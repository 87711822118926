/* IMPORTS */

// CSS import:
import './SocialMedia.scss';
import './SocialMediaMediaQuery.scss';
// Icons import:
import { FaInstagram, FaAirbnb, FaWhatsapp  } from 'react-icons/fa';
import { CgArrowUpO } from "react-icons/cg";
import { Link } from 'react-scroll';



/* COMPONENTS */

// Social Media component:
const SocialMedia = ({ }) => (
    <div className="social-bar">
        <a className="icon icon-instagram" href="https://www.instagram.com/casa_arenacr/" target="_blank">
            <FaInstagram />
        </a>
        <a className="icon icon-airbnb" href="https://www.airbnb.com/rooms/694757470879520340?adults=1&s=42&unique_share_id=1E19FF05-0CF9-4054-BED4-B4F8F531748F&_branch_match_id=1102672488587849955&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT0zKS9LLTdWvjPJOdsqMyi8rSQIAjR%2BX8BsAAAA%3D&source_impression_id=p3_1664135837_Wq8POSIluCN0nKHd" target="_blank">
            <FaAirbnb />
        </a>
        <a className="icon icon-whatsapp" href="https://api.whatsapp.com/send?phone=50660406695&text=Hola!%20Me%20gustaría%20realizar%20una%20consulta%20sobre%20Casa%20Arena." target="_blank">
            <FaWhatsapp />
        </a>
        <Link to='home' className='icon icon-up'> <CgArrowUpO /> </Link>
    </div>
);

export default SocialMedia;