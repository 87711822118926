/* IMPORTS */

// SCSS import:
import './NavBar.scss';
import './NavBarMediaQuery.scss';
// Components import:
import BurgerMenu from '../BurgerMenu/BurgerMenu';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';


/* COMPONENTS */

// NavBar component:
const NavBar = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container fluid className='NavBarContainer' id='home'>
            <Row className='NavBarRow'>
                <Col xl={6} lg={6} md={6} sm={12} className='NavBarLeftContent'>
                    <ul>
                        {/* <li> <Link to='about' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.about-us')} </Link> </li> */}
                        <li> <Link to='gallery' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.gallery')} </Link> </li>
                        <li> <Link to='activities' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.activities')} </Link> </li>
                    </ul>
                </Col>
                <Col className='NavBarRightContent'>
                    <ul>
                        <li> <Link to='villa' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.villa')} </Link> </li>
                        <li> <Link to='contact' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.contact')} </Link> </li>
                        <li>
                            <button onClick={() => i18n.changeLanguage('en')}> {t('nav-bar.language-eng')} </button>
                            |
                            <button onClick={() => i18n.changeLanguage('es')}> {t('nav-bar.language-spa')} </button>
                        </li>
                    </ul>
                </Col>
                <label className='BurgerMenuLabel'> <BurgerMenu/> </label>
            </Row>
        </Container>
    );
};

export default NavBar;