// export const AboutUsImages = [
//     require('./Images/slider00.jpg'),
//     require('./Images/slider2015a.jpg'),
//     require('./Images/slider2005.jpg'),
// ]

export const TheVillaImages = [
    require('./Images/Actualizacion/Villa/C_Arena_sII_46.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_03.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_06.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_07.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_17.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_19.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_26.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_30.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_31.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_40.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_49.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_55.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_57.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_60.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_67.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_74.jpg'),
    require('./Images/Actualizacion/Villa/C_Arena_sII_77.jpg'),
]