/* IMPORTS */

// SCSS import:
import './TheVilla.scss';
import './TheVillaMediaQuery.scss';
// Components import:
import FadeSlider from '..//FadeSlider/FadeSlider';
import { TheVillaImages } from '../../Assets/Assets';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { useTranslation } from 'react-i18next';
import { AiOutlineWifi, AiFillCar } from "react-icons/ai";
import { FaSwimmingPool, FaShower, FaMugHot, FaHandHoldingWater, FaUmbrellaBeach, FaConciergeBell, FaToilet, FaChessQueen, FaChessKing } from "react-icons/fa";
import { BsSafe2, BsThermometerSnow, BsSpeakerFill } from "react-icons/bs";
import { MdCleanHands, MdOutlineMicrowave, MdOutlineBedroomParent } from "react-icons/md";
import { GiSoap, GiCookingPot, GiPalmTree, GiBathtub, GiWashingMachine, GiSofa, GiBarbecue, GiBinoculars, GiPoliceOfficerHead } from "react-icons/gi";
import { IoIosTv } from "react-icons/io";
import { RiFirstAidKitFill, RiHotelBedFill } from "react-icons/ri";
import { SiWindicss } from "react-icons/si";
import { MdBalcony, MdOutlineLiving } from "react-icons/md";


/* COMPONENTS */

// The Villa component:
const TheVilla = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container fluid className='TheVillaContainer' id='villa'>
            <Row className='TheVillaRow'>
                <div className='TheVillaBkgColor'></div>
                <Col className='FadeSliderContainer'>
                    <div className='SliderBkgColor'></div>
                    <FadeSlider images={TheVillaImages}/>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className='TheVillaContent'>
                    <div className='AlignmentDiv'>
                        <div className='TheVillaTextTitle'>
                            <h3> {t('the-villa.title')} </h3>
                        </div>
                        <div className='TheVillaLists'>
                            <ul className='TheVillaList'>
                                    <li> <MdOutlineBedroomParent/> {t('the-villa.li1')} </li>
                                    <li> <FaToilet/> {t('the-villa.li2')} </li>
                                    <li> <FaChessKing/> {t('the-villa.li3')} </li>
                                    <li> <FaChessQueen/> {t('the-villa.li4')} </li>
                                    <li> <RiHotelBedFill/> {t('the-villa.li5')} </li>
                                    <li> <GiBathtub/> {t('the-villa.li6')} </li>
                                    <li> <FaMugHot/> {t('the-villa.li7')} </li>
                                    <li> <GiSoap/> {t('the-villa.li8')} </li>
                                    <li> <SiWindicss/> {t('the-villa.li9')} </li>
                                    <li> <GiCookingPot/> {t('the-villa.li10')} </li>
                            </ul>
                            <ul className='TheVillaList'>
                                    <li> <MdOutlineMicrowave/> {t('the-villa.li11')} </li>
                                    <li> <FaHandHoldingWater/> {t('the-villa.li12')} </li>
                                    <li> <BsSpeakerFill/> {t('the-villa.li13')} </li>
                                    <li> <IoIosTv/> {t('the-villa.li14')} </li>
                                    <li> <AiOutlineWifi/> {t('the-villa.li15')} </li>
                                    <li> <BsThermometerSnow/> {t('the-villa.li16')} </li>
                                    <li> <BsSafe2/> {t('the-villa.li17')} </li>
                                    <li> <RiFirstAidKitFill/> {t('the-villa.li18')} </li>
                                    <li> <MdOutlineLiving/> {t('the-villa.li19')} </li>
                                    <li> <GiWashingMachine/> {t('the-villa.li20')} </li>
                                    <li> <MdCleanHands/> {t('the-villa.li21')} </li>
                            </ul>
                            <ul className='TheVillaList'>
                                    <li> <MdBalcony/> {t('the-villa.li22')} </li>
                                    <li> <GiBinoculars/> {t('the-villa.li23')} </li>
                                    <li> <GiSofa/> {t('the-villa.li24')} </li>
                                    <li> <GiBarbecue/> {t('the-villa.li25')} </li>
                                    <li> <FaShower/> {t('the-villa.li26')} </li>
                                    <li> <FaSwimmingPool/> {t('the-villa.li27')} </li>
                                    <li> <GiPalmTree/> {t('the-villa.li28')} </li>
                                    <li> <FaUmbrellaBeach/> {t('the-villa.li29')} </li>
                                    <li> <AiFillCar/> {t('the-villa.li30')} </li>
                                    <li> <FaConciergeBell/> {t('the-villa.li31')} </li>
                                    <li> <GiPoliceOfficerHead/> {t('the-villa.li32')} </li>
                            </ul>
                            <ul className='TheVillaListMobile'>
                                    <li> <MdOutlineBedroomParent/> {t('the-villa.li1')} </li>
                                    <li> <FaToilet/> {t('the-villa.li2')} </li>
                                    <li> <FaChessKing/> {t('the-villa.li3')} </li>
                                    <li> <FaChessQueen/> {t('the-villa.li4')} </li>
                                    <li> <RiHotelBedFill/> {t('the-villa.li5')} </li>
                                    <li> <GiBathtub/> {t('the-villa.li6')} </li>
                                    <li> <FaMugHot/> {t('the-villa.li7')} </li>
                                    <li> <GiSoap/> {t('the-villa.li8')} </li>
                                    <li> <SiWindicss/> {t('the-villa.li9')} </li>
                                    <li> <GiCookingPot/> {t('the-villa.li10')} </li>
                                    <li> <MdOutlineMicrowave/> {t('the-villa.li11')} </li>
                                    <li> <FaHandHoldingWater/> {t('the-villa.li12')} </li>
                                    <li> <BsSpeakerFill/> {t('the-villa.li13')} </li>
                                    <li> <IoIosTv/> {t('the-villa.li14')} </li>
                            </ul>
                            <ul className='TheVillaListMobile'>
                                    <li> <BsThermometerSnow/> {t('the-villa.li16')} </li>
                                    <li> <BsSafe2/> {t('the-villa.li17')} </li>
                                    <li> <RiFirstAidKitFill/> {t('the-villa.li18')} </li>
                                    <li> <MdOutlineLiving/> {t('the-villa.li19')} </li>
                                    <li> <GiWashingMachine/> {t('the-villa.li20')} </li>
                                    <li> <MdCleanHands/> {t('the-villa.li21')} </li>
                                    <li> <MdBalcony/> {t('the-villa.li22')} </li>
                                    <li> <GiBinoculars/> {t('the-villa.li23')} </li>
                                    <li> <GiSofa/> {t('the-villa.li24')} </li>
                                    <li> <GiBarbecue/> {t('the-villa.li25')} </li>
                                    <li> <FaShower/> {t('the-villa.li26')} </li>
                                    <li> <FaSwimmingPool/> {t('the-villa.li27')} </li>
                                    <li> <GiPalmTree/> {t('the-villa.li28')} </li>
                                    <li> <FaUmbrellaBeach/> {t('the-villa.li29')} </li>
                                    <li> <AiFillCar/> {t('the-villa.li30')} </li>
                                    <li> <FaConciergeBell/> {t('the-villa.li31')} </li>
                                    <li> <GiPoliceOfficerHead/> {t('the-villa.li32')} </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default TheVilla;