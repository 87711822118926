/* IMPORTS */

// SCSS import:
import './Modal.scss';
// React import:
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// Modal component:
const Modal = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container className='ModalContainer'>
            <input type="checkbox" id="btn-modal"></input>
                <label htmlFor="btn-modal" className="lbl-modal"> {t('terms-conditions.terms-button')} </label>

            <div className="ModalOpen">
                <div className="contenedor">
                    <label htmlFor="btn-modal">X</label>
                    <div className='ModalText'>
                        <h4> {t('terms-conditions.terms-button')} </h4>
                        <p> {t('terms-conditions.terms-text')} </p>
                        <h6> {t('terms-conditions.terms-names')} </h6>
                        <p> {t('terms-conditions.terms-names-text')} </p>
                        <h6> {t('terms-conditions.terms-reservation')} </h6>
                        <p> {t('terms-conditions.terms-reservation-text')} </p>
                        <h6> {t('terms-conditions.terms-cancellation')} </h6>
                        <p> {t('terms-conditions.terms-cancellation-text')} </p>
                        <h6> {t('terms-conditions.terms-check')} </h6>
                        <p> {t('terms-conditions.terms-check-text')} </p>
                        <h6> {t('terms-conditions.terms-security-deposit')} </h6>
                        <p> {t('terms-conditions.terms-security-deposit-text')} </p>
                        <h6> {t('terms-conditions.terms-cleaning')} </h6>
                        <p> {t('terms-conditions.terms-cleaning-text')} </p>
                        <h6> {t('terms-conditions.terms-external-services')} </h6>
                        <p> {t('terms-conditions.terms-external-services-text')} </p>
                        <h6> {t('terms-conditions.terms-equipment-malfunction')} </h6>
                        <p> {t('terms-conditions.terms-equipment-malfunction-text')} </p>
                        <h6> {t('terms-conditions.terms-disclaimer-and-liability')} </h6>
                        <p> {t('terms-conditions.terms-disclaimer-and-liability-text')} </p>
                        <p> {t('terms-conditions.terms-text-final')} </p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Modal;