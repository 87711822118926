/* IMPORTS */

// CSS import
import './CarouselHero.scss';
import './CarouselHeroMediaQuery.scss';
// Images
import HomeArena from '../../Assets/Images/HomeArena.jpg';
import HomeDrone from '../../Assets/Images/HomeDrone.jpg';
import HomeLiving from '../../Assets/Images/HomeLiving.jpg';
import HomePileta1 from '../../Assets/Images/Actualizacion/Hero/C_Arena_sII_89-min.jpg';
import HomePileta2 from '../../Assets/Images/HomePileta.jpg';
import HomeDroneMobile from '../../Assets/Images/HomeDroneMobile.jpg';
import HomeLivingMobile from '../../Assets/Images/HomeLivingMobile.jpg';
import HomePileta1Mobile from '../../Assets/Images/HomePileta2Mobile.jpg';
import HomePileta2Mobile from '../../Assets/Images/HomePiletaMobile.jpg';

// React import
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// CarouselHero component
const CarouselHero = () => {
    const [t] = useTranslation("global");


    return (
        <section>
            <Carousel fade interval={null}>

                <Carousel.Item className='CarouselItem'>
                    <img
                    className="d-block w-100 Carousel__heroImage"
                    src={HomeArena}
                    alt="First slide"
                    />
                    <Carousel.Caption className='H1Caption'>
                        <h1>
                            <span> {t('carousel-hero.h1')} </span>
                            <div className="message">
                                <div className="word1">Casa Arena</div>
                            </div>
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item className='CarouselItem'>
                    <div className="NavBar__center">
                        <li><a href="index.html"> <span className='logo'> Casa Arena </span> <br/> <span> <p>Santa Teresa | Costa Rica</p> </span></a></li>
                    </div>
                    <img
                    className="d-block w-100 Carousel__heroImage Mobile"
                    src={HomeDroneMobile}
                    alt="Second slide"
                    />
                    <img
                    className="d-block w-100 Carousel__heroImage drone"
                    src={HomeDrone}
                    alt="Second slide"
                    />
                    <Carousel.Caption className='Caption'>
                        <p> {t('carousel-hero.text1')} </p>
                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item className='CarouselItem'>
                    <div className="NavBar__center">
                        <li><a href="index.html"> <span className='logo'> Casa Arena </span> <br/> <span> <p> Santa Teresa | Costa Rica </p> </span></a></li>
                    </div>
                    <img
                    className="d-block w-100 Carousel__heroImage Mobile"
                    src={HomeLivingMobile}
                    alt="Third slide"
                    />
                    <img
                    className="d-block w-100 Carousel__heroImage bottom"
                    src={HomeLiving}
                    alt="Third slide"
                    />
                    <Carousel.Caption className='Caption'>
                        <p> {t('carousel-hero.text2')} </p>
                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item className='CarouselItem'>
                    <div className="NavBar__center">
                        <li><a href="index.html"> <span className='logo'> Casa Arena </span> <br/> <span> <p> Santa Teresa | Costa Rica </p> </span></a></li>
                    </div>
                    <img
                    className="d-block w-100 Carousel__heroImage Mobile Pile"
                    src={HomePileta1Mobile}
                    alt="Fourth slide"
                    />
                    <img
                    className="d-block w-100 Carousel__heroImage Carousel__beach"
                    src={HomePileta1}
                    alt="Fourth slide"
                    />
                    <Carousel.Caption className='Caption'>
                        <p> {t('carousel-hero.text3')}
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item className='CarouselItem'>
                    <div className="NavBar__center">
                        <li><a href="index.html"> <span className='logo'> Casa Arena </span> <br/> <span> <p> Santa Teresa | Costa Rica </p> </span></a></li>
                    </div>
                    <img
                    className="d-block w-100 Carousel__heroImage Mobile Pile"
                    src={HomePileta2Mobile}
                    alt="Fourth slide"
                    />
                    <img
                    className="d-block w-100 Carousel__heroImage Carousel__beach"
                    src={HomePileta2}
                    alt="Fourth slide"
                    />
                    <Carousel.Caption className='Caption'>
                        <p> {t('carousel-hero.text4')} </p>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
        </section>
    )
};

export default CarouselHero;