/* IMPORTS */

// SCSS import:
import './Contact.scss';
import './ContactMediaQuery.scss';
// Components import:
import Modal from '../Modal/Modal';
import ContactForm from '../ContactForm/ContactForm';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";


/* COMPONENTS */

// Contact component:
const Contact = () => {
    const [t] = useTranslation("global");

    return (
        <Container fluid className='ContactContainer' id='contact'>
            <Row className='ContactRow'>
                <Col xl={6} lg={12} md={12} sm={12} className='ContactHowToArrive'>
                    <div className='ContactTextContent'>
                        <h3> {t('contact.arrive-title')} </h3>
                        <p className='HowToArriveText'> {t('contact.arrive-text')} </p>
                        <p className='info'> <HiLocationMarker /> {t('contact.location')} <br/> <MdEmail /> {t('contact.mail')}  <br/> <IoLogoWhatsapp /> +506 6040 6695  </p>
                        <Modal />
                    </div>
                </Col>
                <Col className='ContactForm'>
                    <div className='ContactFormContent'>
                        <ContactForm />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact;