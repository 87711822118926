/* IMPORTS */

// CSS import:
import './SliderCard.scss';
import './SliderCardMediaQuery.scss';
// React import:
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// SliderCard component:
const SliderCard = () => {
    const [t, i18n] = useTranslation("global");

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


        return (
        <div className='SliderContainer'>
            <h3 className='ActivitiesTitle'> {t('activities.title')} </h3>
            <Slider {...settings} className='Slider'>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image spa'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.spa-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.spa-title')} </h3>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image yoga'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.yoga-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.yoga-title')} </h3>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image surfing'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.surfing-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.surfing-title')} </h3>
                </div >

                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image ride'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.ride-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.ride-title')} </h3>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image fishing'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.fishing-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.fishing-title')} </h3>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image chef'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.chef-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.chef-title')} </h3>
                </div>

                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image kitchen'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.int-kitchen-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.int-kitchen-title')} </h3>
                </div>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image tours'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.tours-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.tours-title')} </h3>
                </div>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image guide'>
                            <div className='CardItem__description'>
                                <p> {t('slider-card.guide-text')} </p>
                            </div >
                        </div>
                    </div>
                    <h3 className='CardTitle'> {t('slider-card.guide-title')} </h3>
                </div>
            </Slider>
        </div>
        );
    }

export default SliderCard;