/* IMPORTS */

// SCSS import:
import './Activities.scss';
import './ActivitiesMediaQuery.scss';
// Components import:
import SliderCard from '../SliderCard/SliderCard';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


/* COMPONENTS */

// Activities component:
const Activities = () => {

    return (
        <Container fluid className='ActivitiesContainer' id='activities'>
            <Row className='ActivitiesRow'>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <SliderCard />
                </Col>
            </Row>
        </Container>
    )
}

export default Activities;