/* IMPORTS */

// SCSS import:
import './GalleryImg.scss';
import './GalleryImgMediaQuery.scss';
// Images:
import Asador from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_08-min.jpg';
import Balcon from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_10-min.jpg';
import Bano from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_16-min.jpg';
import Cocina from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_23-min.jpg';
import Cocina2 from '../../Assets/Images/GalleryCocina2.jpg';
import HabAzul from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_34-min.jpg';
import InteriorH from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_44-min.jpg';
import Living2 from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_45-min.jpg';
import LivingH from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_52-min.jpg';
import LivingV from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_53-min.jpg';
import Paso from '../../Assets/Images/GalleryPaso.jpg';
import Surf from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_62-min.jpg';
import Nocturna from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_63-min.jpg';
import Frente from '../../Assets/Images/Actualizacion/Galeria/C_Arena_sII_66-min.jpg';


// React import:
import React from 'react';
import Container from 'react-bootstrap/Container';



/* COMPONENTS */
// GalleryImg component:
function GalleryImg() {

    return (
        <Container fluid className='GalleryImgContainer' id='gallery'>
            <div className="GalleryWrapper">
                <div className="ImageWrapper ImageWrapper1 Wide">
                    <a href="#lightbox-image-1">
                        <img className='GridItem One' src={InteriorH} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper2">
                    <a href="#lightbox-image-2">
                        <img className='GridItem Two' src={Paso} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper3 Tall">
                    <a href="#lightbox-image-3">
                        <img className='GridItem' src={LivingV} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper4 Tall">
                    <a href="#lightbox-image-4">
                        <img className='GridItem' src={LivingH} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper5">
                    <a href="#lightbox-image-5">
                        <img className='GridItem Five' src={HabAzul} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper6">
                    <a href="#lightbox-image-6">
                        <img className='GridItem Six' src={Surf} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper7">
                    <a href="#lightbox-image-7">
                        <img className='GridItem Seven' src={Cocina} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper8">
                    <a href="#lightbox-image-8">
                        <img className='GridItem Eight' src={Bano} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper9">
                    <a href="#lightbox-image-9">
                        <img className='GridItem' src={Nocturna} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper10">
                    <a href="#lightbox-image-10">
                        <img className='GridItem' src={Living2} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper11">
                    <a href="#lightbox-image-11">
                        <img className='GridItem' src={Cocina2} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper12">
                    <a href="#lightbox-image-12">
                        <img className='GridItem Twelve' src={Balcon} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper13">
                    <a href="#lightbox-image-13">
                        <img className='GridItem Twelve' src={Frente} alt="" />
                    </a>
                </div>

                <div className="ImageWrapper ImageWrapper14 Wide">
                    <a href="#lightbox-image-14">
                        <img className='GridItem Thirteen' src={Asador} alt="" />
                    </a>
                </div>
            </div>


        {/* LIGHTBOXES */}
            <div className="gallery-lightboxes">
                <div className="image-lightbox" id="lightbox-image-1">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-13" className="arrow-left"></a>
                        <a href="#lightbox-image-2" className="arrow-right"></a>
                        <img src={InteriorH} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-2">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-1" className="arrow-left"></a>
                        <a href="#lightbox-image-3" className="arrow-right"></a>
                        <img src={Paso} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-3">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-2" className="arrow-left"></a>
                        <a href="#lightbox-image-4" className="arrow-right"></a>
                        <img src={LivingV} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-4">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-3" className="arrow-left"></a>
                        <a href="#lightbox-image-5" className="arrow-right"></a>
                        <img src={LivingH} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-5">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-4" className="arrow-left"></a>
                        <a href="#lightbox-image-6" className="arrow-right"></a>
                        <img src={HabAzul} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-6">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-5" className="arrow-left"></a>
                        <a href="#lightbox-image-7" className="arrow-right"></a>
                        <img src={Surf} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-7">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-6" className="arrow-left"></a>
                        <a href="#lightbox-image-8" className="arrow-right"></a>
                        <img src={Cocina} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-8">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-7" className="arrow-left"></a>
                        <a href="#lightbox-image-9" className="arrow-right"></a>
                        <img src={Bano} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-9">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-8" className="arrow-left"></a>
                        <a href="#lightbox-image-10" className="arrow-right"></a>
                        <img src={Nocturna} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-10">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-9" className="arrow-left"></a>
                        <a href="#lightbox-image-11" className="arrow-right"></a>
                        <img src={Living2} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-11">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-10" className="arrow-left"></a>
                        <a href="#lightbox-image-12" className="arrow-right"></a>
                        <img src={Cocina2} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-12">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-11" className="arrow-left"></a>
                        <a href="#lightbox-image-13" className="arrow-right"></a>
                        <img src={Balcon} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-13">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-12" className="arrow-left"></a>
                        <a href="#lightbox-image-14" className="arrow-right"></a>
                        <img src={Frente} alt="" />
                    </div>
                </div>

                <div className="image-lightbox" id="lightbox-image-14">
                    <div className="image-lightbox-wrapper">
                        <a href="#gallery" className="close"></a>
                        <a href="#lightbox-image-13" className="arrow-left"></a>
                        <a href="#lightbox-image-1" className="arrow-right"></a>
                        <img src={Asador} alt="" />
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default GalleryImg;