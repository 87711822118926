/* IMPORTS */

// SCSS import:
import './ContactForm.scss';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import { Toaster, toast } from 'react-hot-toast';

/* COMPONENTS */

// ContactForm component:
const ContactForm = () => {
    function sendEmail(e) {
        e.preventDefault();
        toast.success(<img src='https://res.cloudinary.com/dfprmjlir/image/upload/c_scale,w_50/v1662408938/CASA%20ARENA/send_1_ybjyvm.png'></img>);
        emailjs.sendForm('service_l34t1xi','template_3zg43g7',e.target,'1WTtK-E3cyXTDUi21').then(res=> {
            console.log(res);
        }).catch(err=>console.log(err));
        e.target.reset();
    }
    const [t] = useTranslation("global");

    return (
        <Container fluid className='ContactFormContainer'>
            <form
            onSubmit={
                sendEmail
            }
            >
                <Row className='ContactFormRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText'>
                        <label> {t('contact-form.form-name')} </label>
                        <input type='text' name='name' required></input>
                    </Col>
                </Row>
                <Row className='ContactFormRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText'>
                        <label> {t('contact-form.form-country')} </label>
                        <input type='text' name='country' required></input>
                    </Col>
                </Row>
                <Row className='ContactFormRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText'>
                        <label> {t('contact-form.form-mail')} </label>
                        <input type='email' name='mail' required></input>
                    </Col>
                </Row>
                <Row className='ContactFormRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText'>
                        <label> {t('contact-form.form-phone')} </label>
                        <input type='tel' name='phone' required></input>
                    </Col>
                </Row>
                <Row className='ContactFormRow FlexRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText CheckContent Left'>
                        <label className='LabelCheck'> {t('contact-form.form-in')} </label>
                        <input type='date' name='in' className='CheckIn' required></input>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText CheckContent Right'>
                        <label className='LabelCheck'> {t('contact-form.form-out')} </label>
                        <input type='date' name='out' className='CheckOut' required></input>
                    </Col>
                </Row>
                <Row className='ContactFormRow'>
                    <Col xl={6} lg={6} md={12} sm={12} className='ContactFormText'>
                        <label> {t('contact-form.form-message')} </label>
                        <textarea type='text' name='message' className='Message'></textarea>
                    </Col>
                </Row>
                <Row  className='ContactFormRow'>
                    <Col className='ContactFormText Left'>
                        <button className='FormButtons' type='reset'> {t('contact-form.form-reset')} </button>
                    </Col>
                    <Col className='ContactFormText Right'>
                        <button className='FormButtons'> {t('contact-form.form-submit')} </button>
                    </Col>
                </Row>
            </form>
            <Toaster
                toastOptions={{
                    duration: 5000,
                    style: {
                    background: '#c5d6a1',
                    color: '#fff',
                    height: '50px'
                    },
                }}
            />
        </Container>
    )
}

export default ContactForm;