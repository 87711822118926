/* IMPORTS */

// SCSS import:
import './Map.scss';
import './MapMediaQuery.scss';
// Components import:
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// Map component:
const Map = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container fluid className='MapContainer' id='map'>
            <Row className='MapRow'>
                <Col className='MapContent'>
                    <div className='MapImg'></div>
                </Col>
            </Row>
        </Container>
    )
}

export default Map;