/* IMPORTS */

// SCSS import:
import './App.css';
// Components import:
import NavBar from './components/NavBar/NavBar';
import CarouselHero from './components/CarouselHero/CarouselHero';
// import AboutUs from './components/AboutUs/AboutUs';
import GalleryImg from './components/GalleryImg/GalleryImg';
import Activities from './components/Activities/Activities';
import TheVilla from './components/TheVilla/TheVilla';
import Contact from './components/Contact/Contact';
import Map from './components/Map/Map';
import Footer from './components/Footer/Footer';
import SocialMedia from './components/SocialMedia/SocialMedia';
// React import:
import { Helmet } from 'react-helmet';


/* COMPONENTS */

// App component:
const App = () => {

    return (
      <section className='App'>
        <header>
          <Helmet>
            <title>Casa Arena</title>
            <meta
              name='keywords'
              content='costa rica, santa teresa, centroamerica, arena, casa arena, vacaciones, playa, paraiso, holidays, beach, paradise'
            />
            <link rel='canonical' href='https://www.casaarenacr.com'/>
            <meta
              name='description'
              content='Welcome to our little piece of paradise, Casa Arena, our beach surf house at the tropical beach of Santa Teresa, Costa Rica.'
            />

            {/* OPEN GRAPH */}
            <meta
              property="og:title"
              content="Casa Arena"
            />
            <meta
              property="og:description"
              content="Welcome to our little piece of paradise, Casa Arena, our beach surf house at the tropical beach of Santa Teresa, Costa Rica. For the enjoyment of those who are encouraged to feel the Pura Vida life."
            />
            <meta
              property="og:site_name"
              content="Casa Arena"
            />
            <meta
              property="og:url"
              content="https://www.casaarenacr.com"
            />
            <meta
              property="og:type"
              content="beach.holidays"
            />
            <meta
            property='og:image:secure'
            content='https://res.cloudinary.com/dfprmjlir/image/upload/v1662673446/CASA%20ARENA/Captura_de_Pantalla_2022-09-08_a_la_s_18.42.06_g3m3wh.png'
            />
          </Helmet>
          <section className='Home' id='home'>
              <NavBar/>
              <CarouselHero/>
          </section>
        </header>

        <main>
          {/* <section className='AboutUs' id='aboutUs'>
            <AboutUs/>
          </section> */}
          <section className='GalleryImg' id='GalleryImg'>
            <GalleryImg />
          </section>
          <section className='TheVilla' id='theVilla'>
            <TheVilla />
          </section>
          <section className='Activities' id='Tours&Activities'>
            <Activities />
          </section>
          <section className='Contact' id='contact'>
            <Contact />
          </section>
          <section className='Map' id='Map'>
            <Map />
          </section>
        </main>

        <footer>
          <section className='Footer'>
            <Footer />
          </section>
          <section className='SocialMedia'>
            <SocialMedia />
          </section>
        </footer>
      </section>
    )
}

export default App;