import './FadeSlider.scss';
import './FadeSliderMediaQuery.scss';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';


const FadeSlider = props => {

    return (
        <>
        <Swiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            // thumbs={{ swiper: activeThumb }}
            className='ImagesSlider'
        >
        {
            props.images.map((item, index) => (
                <SwiperSlide key={index}>
                    <img className='BigImages' src={item} alt="product images" />
                </SwiperSlide>
            ))
        }
        </Swiper>
        </>
    )
}

FadeSlider.propTypes = {
    images: PropTypes.array.isRequired
}

export default FadeSlider;